<template>
  <v-container fluid>
    <router-view @refreshData="getCrm" />
    <FilterDrawer
      current-component="crm-filter"
      @filter="filterCrm"
      @clear="clearFilter"
    />
    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.title }}</td>
        <td>
          <ul class="pa-0">
            <li>{{ item.client.company_name }}</li>
            <li>{{ item.client.trading_name }}</li>
          </ul>
        </td>
        <td>
          <ContactInfo
            :name="JSON.parse(item.client.client_contact)[0].name"
            :phone-one="JSON.parse(item.client.client_contact)[0].phone_number"
            :phone-two="JSON.parse(item.client.client_contact)[0].cel_number"
            :email="JSON.parse(item.client.client_contact)[0].email"
          />
        </td>
        <td>{{ item.seller.name }}</td>
        <td>
          <StatusChip
            :status="priorityLevelStatus"
            :current-status="item.priority_level"
          />
        </td>
        <td class="text-center">
          <div>{{ item.send_date_local.split(' ')[0] }}</div>
          <div>{{ item.send_date_local.split(' ')[1] }}</div>
        </td>
        <td class="text-center">
          {{ item.return_date | formatDate }}
        </td>
        <td>
          <v-chip
            class="white--text"
            :color="checkSituation(item).alertColor"
          >
            {{ checkSituation(item).alertMessage }}
          </v-chip>
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import ContactInfo from '@/components/data-table/ContactInfo'
import StatusChip from '@/components/data-table/StatusChip'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    ContactInfo,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    priorityLevelStatus: [
      { value: 1, text: 'Baixa', color: 'blue' },
      { value: 2, text: 'Média', color: 'warning' },
      { value: 3, text: 'Alta', color: 'error' },
    ],
    currentSearch: '',
    dataTable: {
      title: 'Atividades CRM',
      new: 'crm-new',
      edit: 'crm-edit',
      context: 'crm-context',
      massAction: 'crm-mass-action',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: false,
        newMenu: false,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Título', value: 'title' },
        { text: 'Empresa', value: 'crm_company', sortable: true },
        { text: 'Contato', value: 'crm_client_contact', sortable: true },
        { text: 'Vendedor', value: 'seller_name', sortable: true },
        {
          text: 'Prioridade',
          value: 'priority_text',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Último contato',
          value: 'send_date',
          width: 200,
          align: 'center',
        },
        {
          text: 'Próximo contato',
          value: 'return_date',
          width: 200,
          align: 'center',
        },
        {
          text: 'Alerta',
          value: 'alert_message',
          align: 'center',
          sortable: true,
        },
      ],
    },
  }),
  created() {
    this.getCrm()

    contextMenu.$once('action', (type) => {
      if (type === 'crms') {
        this.getCrm()
      }
    })
    massAction.$once('action', () => {
      this.getCrm()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getCrm(page)
    },
    search(search) {
      this.currentSearch = search
      this.getCrm()
    },
    getCrm(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('crms', {
          params: {
            page,
            search: this.currentSearch,
          },
        })
        .then((res) => {
          const items = res.data.data.data
          items.forEach((item) => {
            item.crm_company = item.client.company_name
            const contacts = JSON.parse(item.client.client_contact)
            item.crm_client_contact = contacts[0].name
            item.seller_name = item.seller.name
            const priorityMapping = {
              1: 'Baixa',
              2: 'Média',
              3: 'Alta',
            }
            item.priority_text = priorityMapping[item.priority_level] || ''

            item.alert_message = this.checkSituation(item).alertMessage
          })
          this.dataTable.items = items
          this.dataTable.totalPages = res.data.total_pages
          this.dataTable.totalRegister = res.data.total_crm
        })

        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },

    getAlertMessage(item) {
      const today = new Date(Date.now())
      const return_date = new Date(item.return_date)
      const diffTime = return_date - today
      const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24))

      let alertColor = ''
      let alertMessage = ''

      if (diffDays > 0) {
        alertColor = 'blue lighten-1'
        alertMessage = 'Ligação futura'
      } else if (diffDays === 0) {
        alertColor = 'green lighten-1'
        alertMessage = 'Dia de ligar'
      } else if (diffDays < 0 && diffDays >= -7) {
        alertColor = 'orange lighten-1'
        alertMessage = 'Deixou de ligar no dia'
      } else if (diffDays < -7) {
        alertColor = 'red lighten-1'
        alertMessage = 'Ligação atrasada'
      }
      return { alertColor, alertMessage }
    },

    filterCrm(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('crms/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
          this.dataTable.totalPages = res.data.total_pages
          this.dataTable.totalRegister = res.data.total_crm
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getCrm()
    },
    checkSituation(item) {
      if (item) {
        const { alertColor, alertMessage } = this.getAlertMessage(item)
        return { alertColor, alertMessage }
      }
      return { alertColor: 'grey', alertMessage: 'Sem alerta' }
    },
    simulateDateChange() {
      this.dataTable.items.forEach((item, index) => {
        const newReturnDate = new Date()
        newReturnDate.setDate(newReturnDate.getDate() - index)
        item.return_date = newReturnDate.toISOString().split('T')[0]
      })
    },
  },
}
</script>
